import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { isEmpty, pick } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "wc-toast";
import { apiPost, apiGet } from "../utils/apiFetch";
import apiPath from "../utils/apiPath";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(() =>
    localStorage.getItem("token")
      ? jwt_decode(localStorage.getItem("token"))
      : null
  );

  const [heightHeader, setHeightHeader] = useState(0);
  const location = useLocation();
  const [newSidebar, setNewSideBar] = useState(false);
  const [open, setOpen] = useState(true);
  const [content, setContent] = useState(false);
  const [slug, setSlug] = useState("");
  const handleContentToggle = () => {
    setContent(!content);
  };
  const [sokcetId, setSocketId] = useState({});
  const [comission, setComission] = useState(0);
  let [loading, setLoading] = useState(false);
  let [user_coins, setUserCoins] = useState(0.0);
  const [isRefereshLoader, setRefereshLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setNewSideBar(false);
    if (
      location?.pathname?.split("/")[1] !== "match-details" &&
      !isEmpty(sokcetId)
    ) {
      sokcetId.disconnect();
      setSocketId({});
    }
  }, [location]);

  let loginUser = async (body) => {
    let hostname = window.location.hostname;
    hostname = hostname.replace(/^www\./, "");
    hostname = hostname.replace(/^ag\./, "");
    body.website = hostname || "SABAEXCH";
    const { status, data } = await apiPost(
      apiPath.loginUser,
      pick(body, ["username", "password", "uniqueId", "website"])
    );

    if (status === 200) {
      if (data.success) {
        const token = data?.results?.token || null;
        const refresh_token = data?.results?.refresh_token || null;
        setUserCoins(data.results.totalCoins);
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        setUser(jwt_decode(token));
        localStorage.setItem("uniqueId", body.uniqueId);
        //toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("API Error");
    }
  };
  const getCoins = async () => {
    if (!isEmpty(user)) {
      setRefereshLoader(true);
      const { status, data } = await apiGet(apiPath.refreshAmount);
      if (status === 200) {
        if (data.success) {
          setUserCoins(data.results.totalCoins);
          setRefereshLoader(false);
        } else {
          toast.error(data.message);
          setRefereshLoader(false);
        }
      } else {
        toast.error("API Error");
        setRefereshLoader(false);
      }
    }
  };

  let logoutUser = () => {
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    navigate("/login");
  };

  let contextData = {
    user: user,
    user_coins,
    loginUser: loginUser,
    logoutUser: logoutUser,
    getCoins,
    setUserCoins,
    setRefereshLoader: setRefereshLoader,
    isRefereshLoader: isRefereshLoader,
    setComission,
    comission,
    open,
    setOpen,
    content,
    setContent,
    slug,
    setSlug,
    handleContentToggle,
    newSidebar,
    setNewSideBar,
    heightHeader,
    setHeightHeader,
    setSocketId
  };

  useEffect(() => {
    getCoins();
  }, []);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
